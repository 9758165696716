/**
 * Borrowed and modified by Scott Silvi in Jun 2013 from the angular-ui project.
 * https://github.com/angular-ui/ui-select2/blob/master/src/select2.js
 *
 * Enhanced Select2 Dropmenus
 *
 * @AJAX Mode - When in this mode, your value will be an object (or array of objects) of the data used by Select2
 *     This change is so that you do not have to do an additional query yourself on top of Select2's own query
 * @params [options] {object} The configuration options passed to $.fn.select2(). Refer to the documentation
 */
angular.module('ui.select2', []).value('uiSelect2Config', {}).directive('uiSelect2', ['uiSelect2Config', '$timeout', function (uiSelect2Config, $timeout) {

  // PJ: our custom formatter
  var formatter = function (option) {

    // After jQ 1.10 / ng 1.2 upgrade, option is coming in as null sometimes!
    // TODO: figure out why / is this a deeper issue?
    if (option === null) return "";

    var
      content = '',
      prepend = $(option.element).data('prepend'),
      cssClass = $(option.element).data('class');

    // if <option> has a data-prepend property, prepend the a span with class
    if (prepend) {
      content += '<i class="' + prepend + '"></i>';   // using an <i> to avoid select2 style rule collision
    }

    // if <option> has a data-css property, apply to select2 equivalent element
    if (cssClass) {
      content += '<span class="' + cssClass + '">' + option.text + "</span>";
    } else {
      content += option.text;
    }

    return content;
  }

  // PJ: Barometer's defaults. Each instance can override.
  var defaults = {
    minimumResultsForSearch: 6,
    // don't escape any markup - allows custom formatter to render html
    escapeMarkup: function (m) {
      return m
    },
    templateSelection: formatter,
    templateResult: formatter
  }

  var options = defaults;
  if (uiSelect2Config) {
    angular.extend(options, uiSelect2Config);
  }
  return {
    require: 'ngModel',
    scope: {
      watchModel: '@'
    },
    compile: function (tElm, tAttrs) {
      var watch,
        repeatOption,
        repeatAttr,
        isSelect = tElm.is('select'),
        isMultiple = (tAttrs.multiple !== undefined);

      // Enable watching of the options dataset if in use
      if (tElm.is('select')) {
        repeatOption = tElm.find('option[ng-repeat], option[data-ng-repeat]');

        if (repeatOption.length) {
          repeatAttr = repeatOption.attr('ng-repeat') || repeatOption.attr('data-ng-repeat');
          watch = jQuery.trim(repeatAttr.split('|')[0]).split(' ').pop();
        }
      }

      return {
        // SS: Moved some logic into the preLink function in order to handle some of the filterSpec requirements using select2
        pre: function preLink(scope, iElement, iAttrs, controller) {
          if (scope && scope.filter) {
            if (scope.filter.isSingleSelect) {
              iElement.prepend("<option value=''></option>");
            } else {
              iAttrs.$set("multiple", "multiple");
            }
          }
        },
        post: function (scope, elm, attrs, controller) {
          // instance-specific options
          var opts = angular.extend({}, options, scope.$eval(attrs.uiSelect2));

          if (isSelect) {
            // Use <select multiple> instead
            delete opts.multiple;
            delete opts.initSelection;
          } else if (isMultiple) {
            opts.multiple = true;
          }

          if (controller) {
            var select = elm.select2();
            // Watch the model for programmatic changes
            controller.$render = function () {
              if (isSelect) {
                select.val(controller.$viewValue).trigger('change');
              } else {
                if (isMultiple) {
                  if (!controller.$viewValue) {
                    select.val([]).trigger('change');
                  } else if (angular.isArray(controller.$viewValue)) {
                    select.val(controller.$viewValue).trigger('change');
                  } else {
                    select.val(controller.$viewValue).trigger('change');
                  }
                } else {
                  if (angular.isObject(controller.$viewValue)) {
                    select.val(controller.$viewValue).trigger('change');
                  } else if (!controller.$viewValue) {
                    select.val(null).trigger('change');
                  } else {
                    select.val(controller.$viewValue).trigger('change');
                  }
                }
              }
            };

            // Watch the options dataset for changes
            if (watch) {
              scope.$watch(watch, function (newVal, oldVal, scope) {
                if (!newVal) return;
                // Delayed so that the options have time to be rendered
                $timeout(function () {
                  select.val(controller.$viewValue).trigger('change');
                  // Refresh angular to remove the superfluous option
                  elm.trigger('change');
                });
              });
            }

            // Update valid and dirty statuses
            controller.$parsers.push(function (value) {
              var div = elm.prev()
              div
                .toggleClass('ng-invalid', !controller.$valid)
                .toggleClass('ng-valid', controller.$valid)
                .toggleClass('ng-invalid-required', !controller.$valid)
                .toggleClass('ng-valid-required', controller.$valid)
                .toggleClass('ng-dirty', controller.$dirty)
                .toggleClass('ng-pristine', controller.$pristine);
              return value;
            });

            if (!isSelect) {
              // Set the view and model value and update the angular template manually for the ajax/multiple select2.
              elm.bind("change", function () {
                if (scope.$$phase) return;
                scope.$apply(function () {
                  controller.$setViewValue(elm.select2('data'));
                });
              });

              if (opts.initSelection) {
                var initSelection = opts.initSelection;
                opts.initSelection = function (element, callback) {
                  initSelection(element, function (value) {
                    controller.$setViewValue(value);
                    callback(value);
                  });
                };
              }
            }
          }

          if (attrs.ngMultiple) {
            scope.$watch(attrs.ngMultiple, function (newVal) {
              elm.select2(opts);
            });
          }

          if(scope.watchModel) {
            scope.$watch(attrs['ngModel'], function (value) {
              $timeout(function () {
                select.val(controller.$viewValue).trigger('change');
                // Refresh angular to remove the superfluous option
                elm.trigger('change');
              });
            });
          }

          // Initialize the plugin late so that the injected DOM does not disrupt the template compiler
          $timeout(function () {
            var select = elm.select2(opts);

            // Set initial value - I'm not sure about this but it seems to need to be there
            select.val(controller.$viewValue).trigger('change');
            // important!
            controller.$render();

            // Not sure if I should just check for !isSelect OR if I should check for 'tags' key
            if (!opts.initSelection && !isSelect)
              controller.$setViewValue(elm.select2('data'));
          });
        }
      }

    }
  };
}]);