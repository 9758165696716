/*
 * dropdownToggle - Provides dropdown menu functionality in place of bootstrap js
 * @restrict class or attribute
 * @example:
 <li class="dropdown">
 <a class="dropdown-toggle">My Dropdown Menu</a>
 <ul class="dropdown-menu">
 <li ng-repeat="choice in dropChoices">
 <a ng-href="{{choice.href}}">{{choice.text}}</a>
 </li>
 </ul>
 </li>
 */

angular.module('ui.bootstrap.dropdownToggle', []).directive('dropdownToggle',
    ['$document', '$location', '$window', function ($document, $location, $window) {
        var openElement = null,
            closeMenu = angular.noop;
        return {
            restrict: 'CA',
            link: function (scope, element, attrs) {
                scope.$watch('$location.path', function () {
                    closeMenu();
                });
                element.parent().bind('click', function () {
                    closeMenu();
                });
                element.bind('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var elementWasOpen = (element === openElement);
                    if (!!openElement) {
                        closeMenu();
                    }
                    if (!elementWasOpen) {
                        element.parent().addClass('open');
                        openElement = element;
                        closeMenu = function (event) {
                            if (event) {
                                event.preventDefault();
                                event.stopPropagation();
                            }
                            $document.unbind('click', closeMenu);
                            element.parent().removeClass('open');
                            closeMenu = angular.noop;
                            openElement = null;
                        };

                        // PAJ 9/6/2013
                        // set the max-height of the dropdown-menu to the bottom of the screen
                        // TODO: do this for select2 dropdowns
                        fitMenuToScreenBottom = function(menu){
                            var
                                windowScrollTop = $(window).scrollTop(),
                                windowH = $(window).height(),
                                menuTop = menu.offset().top,
                                maxHeight = windowScrollTop + windowH - menuTop - 20;

                            menu.css({
                                maxHeight: maxHeight + 'px'
                            })
                        }

                        // PAJ 9/6/13
                        // fit menu to screen when within main app header, which is fixed
                       fitMenuToScreenBottom(element.parent().find('.dropdown-menu'));

                        // PAJ 8/4/2013
                        // special handling for dropdowns within modals
                        // (overflow auto or hidden results in the dropdown menu being clipped)
                        if (element.closest('.modal-body').length){
                            var
                                toggle = element,
                                originalDropdownHolder = toggle.parent(),
                                originalDropdownMenu = originalDropdownHolder.find('.dropdown-menu'),
                                movedDropdownHolder = $('<div></div>'),
                                toggleOffset = toggle.offset(),
                                originalDropdownHeight = originalDropdownHolder.height(),
                                originalDropdownWidth = originalDropdownHolder.width(),
                                modalBody = toggle.closest('.modal-body'),
                                modalBodyOffset,
                                modalBodyHeight,
                                modalScrollTop,
                                oldCloseMenu = closeMenu;


                            // create a stripped down copy of the original dropdown parent
                            // append the original dropdown menu to it
                            // and place it at the bottom of the DOM at the right X,Y
                            movedDropdownHolder
                                .addClass(originalDropdownHolder.attr('class'))
                                .css({
                                    position: 'absolute',
                                    top: toggleOffset.top,
                                    left: toggleOffset.left,
                                    height: originalDropdownHeight + 'px',
                                    width: originalDropdownWidth + 'px',
                                    zIndex: 9999
                                })
                                .append(originalDropdownMenu)
                                .appendTo('body')
                                .on('click', function(){
                                    closeMenu();
                                });

                            // update display on scroll of modal body
                            modalBody.on('scroll', function(){

                                // check to see if the dropdown-toggle has been scrolled out of view...
                                // (only check y-axis - assuming no horizontal scroll)
                                toggleOffset = toggle.offset();
                                modalBodyOffset = modalBody.offset();
                                modalBodyHeight = modalBody.height();
                                modalScrollTop = modalBody.scrollTop();
                                if (toggleOffset.top + originalDropdownHeight < modalBodyOffset.top ||
                                    toggleOffset.top + originalDropdownHeight > (modalBodyOffset.top + modalBodyHeight + modalScrollTop)) {

                                    // if so, hide it
                                    movedDropdownHolder.removeClass('open');

                                } else {

                                    // otherwise open/reposition it
                                    movedDropdownHolder
                                        .addClass('open')
                                        .css(
                                           {
                                               top: toggleOffset.top,
                                               left: toggleOffset.left
                                           })
                                }
                            });

                            // do DOM cleanup on closeMenu
                            closeMenu = function (event) {
                                oldCloseMenu(event);
                                originalDropdownHolder.append(originalDropdownMenu);
                                movedDropdownHolder.remove();
                                modalBody.off('scroll');
                            };

                        }

                        $document.bind('click', closeMenu);

                    }
                });
            }
        };
    }]);